const facebook = {
  appId: "'567741136763695'",
  version: "'v2.8'",
  xbfml: 'true',
}

const googleOptimizeContainerId = 'GTM-WX4K3HC'

module.exports = {
  facebook,
  googleOptimizeContainerId,
}
